/* import React from 'react'
import { Spinner } from 'reactstrap'

const Loader = ({ visible }) => (visible &&
  <div className='loader' style={{ position: 'fixed', backgroundColor: 'rgba(0, 0, 0, 0.5)', inset: '0px', zIndex: '10', paddingTop: '40vh', paddingLeft: '45%' }}>
    <Spinner color='success' style={{ width: '8rem', height: '8rem', border: '10px solid currentColor', borderRightColor: 'transparent' }} />
  </div>
)

export default Loader */


import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Dialog, DialogContent } from '@material-ui/core';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    position: 'fixed',
    inset: '0px',
    zIndex: '10',
    paddingTop: '40vh',
    paddingLeft: '0%'
  },
}));

const Loader = ({ visible }) => {
  const classes = useStyles();

  return ((visible && (
    <>

      <Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={visible}
          onClose={visible}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <LinearProgress />
            <LinearProgress color="secondary" />
            <h2 style={{textAlign : "center"}}>...Cargando</h2>
          </DialogContent>
          {/*  <div className={classes.root}>
            
          </div> */}
        </Dialog>
      </Fragment>

    </>

  ))

  );
}

export default Loader