import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { loginUser } from '../api/user/login';

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const AccessToken = this.getAccessToken();

		if (!AccessToken) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(AccessToken)) {
			this.setSession(AccessToken);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post('/api/auth/register', data).then(response => {
				if (response.data.user) {
					this.setSession(response.data.access_token);
					resolve(response.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = async (email, password) => {
		const { be, ...loginUserRsp } = await loginUser({
			email,
			password
		});
		if (loginUserRsp.data.status === 0) {
			// DO SOMETHING IF WRONG
			return {
				status: 0,
				message: loginUserRsp.data.codeMessages[0]
			};
		}

		const userInfo = {
			role: [], // guest
			data: {
				displayName: 'Santi me la pela 1',
				photoURL: 'assets/images/avatars/profile.jpg',
				email: 'johndoe@withinpixels.com',
				shortcuts: ['calendar', 'mail', 'contacts', 'todo']
			}
		};

		const { data } = loginUserRsp;

		this.setSession(data.token);

		return {
			status: 1,
			userInfo
		};

	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get('/api/auth/access-token', {
					data: {
						access_token: this.getAccessToken()
					}
				})
				.then(response => {
					if (response.data.user) {
						this.setSession(response.data.access_token);
						resolve(response.data.user);
					} else {
						this.logout();
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					reject(new Error('Failed to login with token.'));
				});
		});
	};

	updateUserData = user => {
		return axios.post('/api/auth/user/update', {
			user
		});
	};

	setSession = AccessToken => {
		if (AccessToken) {
			localStorage.setItem('jwt_access_token', AccessToken);
			axios.defaults.headers.common.Authorization = `Bearer ${AccessToken}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			window.location.href = '/home';
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = AccessToken => {
		if (!AccessToken) {
			return false;
		}
		const decoded = jwtDecode(AccessToken);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}

		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};

	getAccessTokenDecoded = () => {
		const token = window.localStorage.getItem('jwt_access_token');

		let tokenDecoded;
		token ? (tokenDecoded = jwtDecode(token)) : null;

		return tokenDecoded;
	};
}

const instance = new JwtService();

export default instance;
