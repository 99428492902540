import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import superAdminNavigation from './navigations/superAdminNavigation';
import transformerNavigation from './navigations/transformerNavigation';
import managerNavigation from './navigations/managerNavigation';
import verifierNavigation from './navigations/verifierNavigation';
import collectiveplanNavigation from './navigations/collectiveplanNavigation';
import producerNavigation from './navigations/producerNavigation';


i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);



 const navigationConfig = {
	manager : 
		managerNavigation,
	collectiveplan : 
		collectiveplanNavigation,
	producer: 
	    producerNavigation,
	transformer : 
		transformerNavigation,
	superadmin : 
		superAdminNavigation,
	verifier : 
		verifierNavigation,
	default : [
		{
			id: 'home',
			title: 'Inicio',
			type: 'group',
			icon: 'pages',
			url: '/home',
			children: []
		},
		{
			id: 'abuoutUs',
			title: 'Contacto',
			type: 'group',
			icon: 'help_outline',
			url: '/contact',
			children: []
		},
		{
			id: 'auth',
			title: 'Autenticación',
			type: 'group',
			icon: 'verified_user',
			children: [
				{
					id: 'login',
					title: 'Iniciar sesion',
					type: 'item',
					url: '/auth/login',
					icon: 'lock'
				},
				{
					id: 'register',
					title: 'Registrarse',
					type: 'item',
					url: '/auth/register',
					icon: 'person_add'
				},
			]
		}
	],
	
};
 

export default navigationConfig;
