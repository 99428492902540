const managerNavigation = [
    {
        id: 'manager',
        title: 'Registro de material',
        type: 'group',
        icon: 'verified_user',
        children: [
            {
                id: 'register',
                title: 'Material entregado',
                type: 'item',
                url: '/manager/register',
                icon: 'lock'
            },
            {
                id: 'register',
                title: 'Material facturado',
                type: 'item',
                url: '/manager/factured',
                icon: 'lock'
            },
        ]
    },
    {
		id: 'manager-certificates',
		title: 'Certificados',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'manager-certificate-avaibles',
				title: 'Disponibles',
				type: 'item',
				icon: 'history',
				url: '/certificates/manager/avaibles',
			}
		]
	},
    {
		id: 'manager-consults',
		title: 'Consultas',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'manager-delivered-material-info',
				title: 'Entrega de material',
				type: 'item',
				icon: 'history',
				url: '/manager/consults/delivered/material-info',
			},
            {
				id: 'manager-invoiced-info',
				title: 'Facturación de material',
				type: 'item',
				icon: 'history',
				url: '/manager/consults/invoiced-info',
			},
            {
				id: 'manager-received-transfer-info',
				title: 'Certificados recibidos',
				type: 'item',
				icon: 'history',
				url: '/manager/consults/received/transfer-info',
			},
            {
				id: 'manager-send-transfer-info',
				title: 'Certificados transferidos',
				type: 'item',
				icon: 'history',
				url: '/manager/consults/send/transfer-info',
			}
		]
	},
]

export default managerNavigation;
