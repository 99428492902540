import { lazy } from 'react';

const NominationCollectivePlanConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/plan/consult/nomination-info',
			component: lazy(() => import('./NominationContainer'))
		}
	]
};

export default NominationCollectivePlanConfig;



