const collectiveplanNavigation = [
	{
		id: 'collectiveplan-certificates',
		title: 'Certificados',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'collectiveplan-certificate-avaibles',
				title: 'Disponibles',
				type: 'item',
				icon: 'history',
				url: '/certificates/collectiveplan/avaibles',
			},
			{
				id: 'collectiveplan-certificate-nominees',
				title: 'Nominados',
				type: 'item',
				icon: 'history',
				url: '/certificates/collectiveplan/nominees',
			}
		]
	},
	{
		id: 'plan-consults',
		title: 'Consultas',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'plan-received-transfer-info',
				title: 'Certificados recibidos',
				type: 'item',
				icon: 'history',
				url: '/plan/consult/received-transfer-info',
			},
            {
				id: 'plan-transfer',
				title: 'Certificados disponibles',
				type: 'item',
				icon: 'history',
				url: '/plan/consult/transfer',
			},
            {
				id: 'plan-send-transfer-info',
				title: 'Certificados transferidos',
				type: 'item',
				icon: 'history',
				url: '/plan/consult/send-transfer-info',
			},
            {
				id: 'plan-nomination-info',
				title: 'Certificados nominados',
				type: 'item',
				icon: 'history',
				url: '/plan/consult/nomination-info',
			}
		]
	},
];

export default collectiveplanNavigation;
