import Slider from 'react-slick';
import logoAccept from "../../../../images/aceptar.svg";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CardHeader
} from '@material-ui/core';

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      <div>
        <Card sx={{ maxWidth: 345, position: 'relative', display: 'flex', flexDirection: 'column' }}>
          <div style={{ position: 'absolute', top: 0, right: 0, padding: '8px', zIndex: 1 }}>
            <img src={logoAccept} className="icon" alt="Celsia" style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
          </div>

          <CardContent style={{ flex: 1 }}>
            <Typography gutterBottom variant="h5" component="div">
              EcoREP
            </Typography>
            <Typography variant="body2" color="text.secondary">
            Cuenta con el respaldo y experiencia tecnológica de XM en la gestión de activos ambientales.
            </Typography>
          </CardContent>

        </Card>
      </div>

      {/* Agrega más tarjetas según sea necesario */}
      <div>
      <Card sx={{ maxWidth: 345, position: 'relative', display: 'flex', flexDirection: 'column' }}>
          <div style={{ position: 'absolute', top: 0, right: 0, padding: '8px', zIndex: 1 }}>
            <img src={logoAccept} className="icon" alt="Celsia" style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
          </div>

          <CardContent style={{ flex: 1 }}>
            <Typography gutterBottom variant="h5" component="div">
              EcoREP
            </Typography>
            <Typography variant="body2" color="text.secondary">
            La información registrada es confiable y segura, sin doble contabilidad
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div>
      <Card sx={{ maxWidth: 345, position: 'relative', display: 'flex', flexDirection: 'column' }}>
          <div style={{ position: 'absolute', top: 0, right: 0, padding: '8px', zIndex: 1 }}>
            <img src={logoAccept} className="icon" alt="Celsia" style={{ width: '80px', height: '80px', borderRadius: '50%' }} />
          </div>

          <CardContent style={{ flex: 1 }}>
            <Typography gutterBottom variant="h5" component="div">
              EcoREP
            </Typography>
            <Typography variant="body2" color="text.secondary">
            No tiene costo por inscripción ni costos por afiliaciones mensuales, solo paga por el uso de los certificados.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Slider>
  );
};

export default Carousel;