import { lazy } from 'react';

const DeliveredTransformerMaterialConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/transformer/consult/delivered-material',
			component: lazy(() => import('./DeliveredMaterialContainer'))
		}
	]
};

export default DeliveredTransformerMaterialConfig;

