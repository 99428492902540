const producerNavigation = [
	{
		id: 'producer-certificates',
		title: 'Certificados',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'producer-certificate-avaibles',
				title: 'Disponibles',
				type: 'item',
				icon: 'history',
				url: '/certificates/producer/avaibles',
			},
			{
				id: 'producer-certificate-nominees',
				title: 'Nominados',
				type: 'item',
				icon: 'history',
				url: '/certificates/producer/nominees',
			}
		]
	},
    {
		id: 'producer-consults',
		title: 'Consultas',
		type: 'group',
		icon: 'account_tree',
		children: [
			{
				id: 'producer-received-transfer-info',
				title: 'Certificados recibidos',
				type: 'item',
				icon: 'history',
				url: '/producer/consult/received-transfer-info',
			},
            {
				id: 'producer-transfer',
				title: 'Certificados disponibles',
				type: 'item',
				icon: 'history',
				url: '/producer/consult/transfer',
			},
            {
				id: 'producer-send-transfer-info',
				title: 'Certificados transferidos',
				type: 'item',
				icon: 'history',
				url: '/producer/consult/send-transfer-info',
			},
            {
				id: 'producer-nomination-info',
				title: 'Certificados nominados',
				type: 'item',
				icon: 'history',
				url: '/producer/consult/nomination-info',
			}
		]
	},
];

export default producerNavigation;
