import jwtDecode from 'jwt-decode'

const tokenName = 'jwt_access_token'

export const saveToken = (token) => {
  try {
    window.localStorage.setItem(tokenName, token)
    return true
  } catch (error) {
    return false
  }
}

const isTimestampExpired = exp => Date.now() > (exp * 1000)

const isTokenExpired = token => isTimestampExpired(token.exp)

export const removeUserToken = () => {
  window.localStorage.removeItem(tokenName)
}

export const authentication = (token) => {
  if (token) {
    const tokenInfo = jwtDecode(token)
    if (!isTokenExpired(tokenInfo)) return tokenInfo
    removeUserToken()
  }
  return undefined
}

const getToken = () => window.localStorage.getItem(tokenName)

const isUserLoggedIn = () => {
  const token = getToken()
  const tokenInfo = authentication(token)
  return !!tokenInfo && !isTokenExpired(tokenInfo)
}

export const userLogout = () => {
  if (isUserLoggedIn()) {
    removeUserToken()
  }
}

export const getUserToken = (alreadyValidated = false) => {
  if (alreadyValidated || isUserLoggedIn()) {
    return getToken()
  }
  return undefined
}

export const getAuthInfo = (alreadyValidated = false) => {
  const token = getUserToken(alreadyValidated)
  return authentication(token)
}

export default isUserLoggedIn
