import styled from "styled-components";
import forest from "../../../images/forest.jpg";
import slideEarth from "../../../images/slide-earth.jpg";
import backgroundSection3 from "../../../images/backgroundSection3.jpg";
import backgroundSection5 from "../../../images/backgroundSection5.jpg";

export const StyledContact = styled.div`
  border-top: none;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  color: #1b1b1b;
  .section7 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundSection5});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    width: 100%;
    align-items: center;
    height: calc(100vh - 450px);
    position: relative;
    filter: brightness(0.9);
    z-index:1
    visibility: inherit;
    color: #fff;
    h1 {
      margin-top : 2%
    }
    .logo {
      color: #fff;
      position: absolute;
      bottom: 25%;
      left: 5%;
      img {
        max-width: 50%;
        border-bottom: 5px solid #fff;
      }
    }
  }
`;
