import styled from "styled-components";
import forest from "../../../images/forest.jpg";
import slideEarth from "../../../images/slide-earth.jpg";
import backgroundSection3 from "../../../images/backgroundSection3.jpg";
import backgroundSection5 from "../../../images/backgroundSection5.jpg";

export const StyledHome = styled.div`
  border-top: none;
  -webkit-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  color: #1b1b1b;
  .img {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${slideEarth});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    opacity:
    width: 100%;
    align-items: center;
    height: calc(100vh);
    position: relative;
    filter: brightness(0.9);
    z-index:1
    opacity: 2;
    visibility: inherit;
    .background {
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      height: 100%;
      width: 100%;
    }
    .logo {
      color: #fff;
      position: absolute;
      bottom: 25%;
      left: 5%;
      img {
        max-width: 50%;
        border-bottom: 5px solid #fff;
      }
    }
  }
  .colorh4 {
    color: #69ade5;
  }
  .steps {
    text-align: center;
    padding: 100px
  }
  .section3 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundSection3});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    width: 100%;
    align-items: center;
    height: calc(100vh - 300px);
    position: relative;
    filter: brightness(0.9);
    z-index:1
    visibility: inherit;
    color: #fff;
    h1 {
      margin-top : 5%
    }
    .logo {
      color: #fff;
      position: absolute;
      bottom: 25%;
      left: 5%;
      img {
        max-width: 50%;
        border-bottom: 5px solid #fff;
      }
    }
  }
  .section4 {
    
  }
  .section5 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundSection5});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    width: 100%;
    align-items: center;
    height: calc(100vh - 400px);
    position: relative;
    filter: brightness(0.9);
    z-index:1
    visibility: inherit;
    color: #fff;
    h1 {
      margin-top : 3%
    }
    .logo {
      color: #fff;
      position: absolute;
      bottom: 25%;
      left: 5%;
      img {
        max-width: 50%;
        border-bottom: 5px solid #fff;
      }
    }
  }

  .section6 {
      text-align: center;
      padding: 50px
  }

  .section7 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundSection5});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    width: 100%;
    align-items: center;
    height: calc(100vh - 400px);
    position: relative;
    filter: brightness(0.9);
    z-index:1
    visibility: inherit;
    color: #fff;
    h1 {
      margin-top : 3%
    }
    .logo {
      color: #fff;
      position: absolute;
      bottom: 25%;
      left: 5%;
      img {
        max-width: 50%;
        border-bottom: 5px solid #fff;
      }
    }
  }
`;
