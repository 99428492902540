import { StyledContact } from "./StyledContact";
import { Button, Dialog, DialogContent, FormControl, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Loader from "app/common/Loader";
import Typography from '@material-ui/core/Typography';
import { Controller, useForm } from 'react-hook-form';
import { transferCheckPayment, saveInfoContact } from "app/services/api/admin/charges";
import { useState } from "react";
import LoadSuccess from "../components/load-success/LoadSuccess";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    email: Yup.string().required('El email es obligatorio'),
    company: Yup.string().required('La compañia es obligatorio'),
    topic: Yup.string().required('El tema es obligatorio'),
    message: Yup.string().required('El mensaje es obligatorio')
});

const Contact = props => {
    const [loading, setLoading] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            company: '',
            topic: '',
            message: ''
        },
        validationSchema,
        onSubmit: async (initialValues) => {
            setLoading(true);

            const dataSend = {
                ...initialValues
            };

            const { be, ...registerRspContact } = await saveInfoContact(dataSend);

            if (be !== 'ok') {
                setStatusError(true);
                setLoading(false);
                setTimeout(() => {
                    setStatusError(false);
                }, 3000);
                return
            }
            const { data } = registerRspContact

            if (data.status !== 1) {
                setStatusError(true);
                setLoading(false);
                setMessageError(data.codeMessages[0].message)
                setTimeout(() => {
                    setStatusError(false);
                }, 3000);
                return
            }
            setLoading(false);
            setShowModalSuccess(true)

            setTimeout(() => {
                setShowModalSuccess(false);
                window.location.reload();
            }, 3000);        },

    });

    return (
        <>
            <Loader visible={loading} />
            <Dialog open={showModalSuccess} centered>
                <DialogContent>
                    <LoadSuccess message="" />
                </DialogContent>
            </Dialog>
            <StyledContact>

                <div className="section7" style={{ marginTop: '0px' }} >
                    <div style={{ textAlign: 'center', }}>
                        <br />
                        <h1 style={{ color: '#fff' }}>
                            Contáctanos <br />
                        </h1>
                    </div>
                </div>
                <div className={clsx('flex-col flex-auto items-center justify-center p-16 sm:p-32')}>
                    <div className=" flex-col items-center justify-center w-fullWidth">
                        <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                            <Card className="w-full ">
                                <CardContent>
                                    <Grid container spacing={2} md={12}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className="mb-16"
                                                label="Nombre"
                                                type="name"
                                                name="name"
                                                variant="outlined"
                                                onChange={formik.handleChange}
                                                value={formik.values.name}
                                                error={formik.errors.name && formik.touched.name}
                                                helperText={formik.errors.name && formik.touched.name && formik.errors.name}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className="mb-16"
                                                label="E-mail"
                                                type="email"
                                                name="email"
                                                variant="outlined"
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                                error={formik.errors.email && formik.touched.email}
                                                helperText={formik.errors.email && formik.touched.email && formik.errors.email}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className="mb-16"
                                                label="Compañia"
                                                type="name"
                                                name="company"
                                                variant="outlined"
                                                onChange={formik.handleChange}
                                                value={formik.values.company}
                                                error={formik.errors.company && formik.touched.company}
                                                helperText={formik.errors.company && formik.touched.company && formik.errors.company}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                className="mb-16"
                                                label="Tema"
                                                type="name"
                                                name="topic"
                                                variant="outlined"
                                                onChange={formik.handleChange}
                                                value={formik.values.topic}
                                                error={formik.errors.topic && formik.touched.topic}
                                                helperText={formik.errors.topic && formik.touched.topic && formik.errors.topic}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                className="mb-16"
                                                label="Mensaje"
                                                type="name"
                                                name="message"
                                                variant="outlined"
                                                onChange={formik.handleChange}
                                                value={formik.values.message}
                                                error={formik.errors.message && formik.touched.message}
                                                helperText={formik.errors.message && formik.touched.message && formik.errors.message}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button
                                        color="primary"
                                        variant='contained'
                                        onClick={() => formik.handleSubmit()}
                                    >
                                        Enviar
                                    </Button>
                                </CardContent>
                            </Card>
                        </motion.div>
                    </div>
                </div>

            </StyledContact>
        </>

    )
}


export default Contact