import LoginPageConfig from './auth/login/LoginPageConfig';
import RegisterPageConfig from "./auth/register/RegisterPageConfig";
import ManagerPageRegisteredConfig from './manager/Registered/ManagerPageRegisteredConfig';
import ManagerPageFacturedConfig from './manager/Factured/ManagerPageFacturedConfig';
import AdmCompaniesPageConfig from "./administration/companies/AdmCompaniesPageConfig";
import TransformerPageValidationMaterialConfig from './transformer/delivery-material/validation-material/TransformerPageValidationMaterialConfig'
import TransformerRegisterMaterialDeliveryConfig from './transformer/delivery-material/register-material-delivery/TransformerRegisterMaterialDeliveryConfig';
import TransformerPageFacturedConfig from './transformer/factured-material/TransformerPageFacturedConfig';
import TransformedPageMaterialConfig from './transformer/transformed-material/TransformedPageMaterialConfig';
import AdmPageCreateCompanieVerifierConfig from './administration/companies-verifier/AdmPageCreateCompanieVerifierConfig';
import countUserPageConfiguration from './transformer/configuration-page/count-user/CountUserPageConfiguration';
import TransformerPageCertifiedMaterialConfig from './transformer/certified-material/TransformerPageCertifiedMaterialConfig';
import CertifiedAvailablePageConfig from './transformer/certified-available/CertifiedAvailablePageConfig';
import CertificatesInVerificationPageConfig from './transformer/certificates-in-verification/CertificatesInVerificationPageConfig';
import VerificatorPageCertifiedConfig from './verificator/certifiesToVerifier/VerificatorPageCertifiedConfig';
import CertifierVerifiedPageConfig from './verificator/certifiesVerifieds/CertifierVerifiedPageConfig';
import ChargesPageConfig from './administration/charges/ChargesPageConfig';
import CertifiedAvailableProducerPageConfig from './producer/certified-available/CertifiedAvailableProducerPageConfig';
import CertifiedNomineesProducerPageConfig from './producer/certified-nominees/CertifiedNomineesProducerPageConfig';
import CertifiedAvailableCollectivePlanPageConfig from './collectiveplan/certified-available/CertifiedAvailableCollectivePlanPageConfig';
import CertifiedNomineesCollectivePlanPageConfig from './collectiveplan/certified-nominees/CertifiedNomineesCollectivePlanPageConfig';
import CertifiedAvailableManagerPageConfig from './manager/certified-available/CertifiedAvailableManagerPageConfig';
import CertifiedAvailableVerificatorPageConfig from './verificator/certified-available/CertifiedAvailableVerificatorPageConfig';
import DeliveredMaterialConfig from './manager/consults/deliveredMaterialInfo/DeliveredMaterialConfig';
import InvoicedConfig from './manager/consults/invoicedInfo/InvoicedConfig';
import ReceivedTransferConfig from './manager/consults/receivedTransferInfo/ReceivedTransferConfig';
import SendTransferConfig from './manager/consults/sendTransferInfo/SendTransferConfig';
import AprrovedCertificationConfig from './transformer/consults/aprrovedCertification/AprrovedCertificationConfig';
import DeliveredTransformerMaterialConfig from './transformer/consults/deliveredMaterial/DeliveredMaterialConfig';
import InvoicedTransformerConfig from './transformer/consults/invoicedMaterial/InvoicedConfig';
import PendingCertificationConfig from './transformer/consults/pendingCertification/PendingCertificationConfig';
import ReceivedTransformerTransferConfig from './transformer/consults/receivedTransfer/ReceivedTransferConfig';
import RejectedCertificationConfig from './transformer/consults/rejectedCertification/RejectedCertificationConfig';
import SendTransformerTransferConfig from './transformer/consults/sendTransfer/SendTransferConfig';
import TransformedMaterialConfig from './transformer/consults/transformedMaterial/TransformedMaterialConfig';
import NominationProducerConfig from './producer/consults/nomination/NominationConfig';
import ReceivedProducerTransformerTransferConfig from './producer/consults/receivedTransfer/ReceivedTransferConfig';
import SendProducerTransformerTransferConfig from './producer/consults/sendTransfer/SendTransferConfig';
import AprrovedProducerCertificationConfig from './producer/consults/transfer/AprrovedCertificationConfig';
import NominationCollectivePlanConfig from './collectiveplan/consults/nomination/NominationCollectivePlanConfig';
import ReceivedTransferCollectivePlanConfig from './collectiveplan/consults/receivedTransfer/ReceivedTransferCollectivePlanConfig';
import SendTransferCollectivePlanConfig from './collectiveplan/consults/sendTransfer/SendTransferCollectivePlanConfig';
import AprrovedCertificationCollectivePlanConfig from './collectiveplan/consults/transfer/AprrovedCertificationCollectivePlanConfig';
import DeliveredAdmMaterialConfig from './administration/consults/deliveredMaterialInfo/DeliveredMaterialConfig';
import AprrovedAdmCertificationConfig from './administration/consults/aprrovedCertification/AprrovedCertificationConfig';
import InvoicedAdmConfig from './administration/consults/invoicedInfo/InvoicedConfig';
import PendingAdmCertificationConfig from './administration/consults/pendingCertification/PendingCertificationConfig';
import RejectedAdmCertificationConfig from './administration/consults/rejectedCertification/RejectedCertificationConfig';
import TransformedAdmMaterialConfig from './administration/consults/transformedMaterial/TransformedMaterialConfig';
import TransformedAdmHistoryConfig from './administration/consults/transformedHistory/TransformedHistoryConfig';
import passwordRecoveryConfig from './auth/passwordRecovery/passwordRecoveryConfig';
import AssociatedTransformersConfig from './verificator/consults/associatedTransformers/AssociatedTransformersConfig';
import AprrovedCertificationVerifierConfig from './verificator/consults/aprrovedCertification/AprrovedCertificationConfig';
import ReceivedVerifierTransferConfig from './verificator/consults/receivedTransfer/ReceivedTransferConfig';
import RejectedCertificationVerifierConfig from './verificator/consults/rejectedCertification/RejectedCertificationConfig';
import SendTransfersVerifierConfig from './verificator/consults/sendTransfer/SendTransferConfig';


const pagesConfigs = [
    LoginPageConfig,
    RegisterPageConfig,
    ManagerPageFacturedConfig,
    AdmCompaniesPageConfig,
    ManagerPageRegisteredConfig,
    TransformerPageValidationMaterialConfig,
    TransformerRegisterMaterialDeliveryConfig,
    TransformerPageFacturedConfig,
    TransformedPageMaterialConfig,
    AdmPageCreateCompanieVerifierConfig,
    countUserPageConfiguration,
    TransformerPageCertifiedMaterialConfig,
    VerificatorPageCertifiedConfig,
    CertifiedAvailablePageConfig,
    CertificatesInVerificationPageConfig,
    CertifierVerifiedPageConfig,
    CertifiedAvailableCollectivePlanPageConfig,
    ChargesPageConfig,
    CertifiedAvailableProducerPageConfig,
    CertifiedNomineesProducerPageConfig,
    CertifiedNomineesCollectivePlanPageConfig,
    CertifiedAvailableManagerPageConfig,
    CertifiedAvailableVerificatorPageConfig,
    DeliveredMaterialConfig,
    InvoicedConfig,
    ReceivedTransferConfig,
    SendTransferConfig,
    AprrovedCertificationConfig,
    DeliveredTransformerMaterialConfig,
    InvoicedTransformerConfig,
    PendingCertificationConfig,
    ReceivedTransformerTransferConfig,
    RejectedCertificationConfig,
    SendTransformerTransferConfig,
    TransformedMaterialConfig,
    NominationProducerConfig,
    ReceivedProducerTransformerTransferConfig,
    SendProducerTransformerTransferConfig,
    AprrovedProducerCertificationConfig,
    NominationCollectivePlanConfig,
    ReceivedTransferCollectivePlanConfig,
    SendTransferCollectivePlanConfig,
    AprrovedCertificationCollectivePlanConfig,
    DeliveredAdmMaterialConfig,
    AprrovedAdmCertificationConfig,
    AprrovedCertificationVerifierConfig,
    InvoicedAdmConfig,
    PendingAdmCertificationConfig,
    RejectedAdmCertificationConfig,
    TransformedAdmMaterialConfig,
    TransformedAdmHistoryConfig,
    passwordRecoveryConfig,
    AssociatedTransformersConfig,
    ReceivedVerifierTransferConfig,
    RejectedCertificationVerifierConfig,
    SendTransfersVerifierConfig
];





export default pagesConfigs