import { createContext, useState } from 'react'
import { getUserToken } from '../helpers/sessionValidation'

const defaultValues = {
  token: '',
  setToken: () => {},
  loading: false,
  setLoading: () => {}
}

export const appContext = createContext(defaultValues)

const AppProvider = ({ children }) => {
  /* ----- start useState ----- */
  const [token, setToken] = useState(getUserToken())
  const [loading, setLoading] = useState(false)
  /* ----- end useState ----- */

  const values = {
    token,
    setToken,
    loading,
    setLoading
  }
  return (
    <appContext.Provider value={values}>
      {children}
    </appContext.Provider>
  )
}

export default AppProvider
