import { lazy } from 'react';

const DeliveredAdmMaterialConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/adm/material-delivery',
			component: lazy(() => import('./DeliveredMaterialContainer'))
		}
	]
};

export default DeliveredAdmMaterialConfig;