import { GET, POST } from '..'


export const transferCheckPayment  = (data) => {
  return POST('/api/user/transfer-check-payment', data)
}

export const getTransferPendingPaymentInfo = () => {
  return GET(`/api/user/transfer-pending-payment`)
}

export const saveInfoContact = (data) => {
  return POST(`/api/user/new-contact`, data)
}
