import { lazy } from 'react';

const CertifierVerifiedPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/verified-certifier',
			component: lazy(() => import('./CertifierVerifiedPage'))
		}
	]
};

export default CertifierVerifiedPageConfig;