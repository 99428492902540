import { lazy } from 'react';

const RegisterPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: true,
					style: 'static'
				},
			}
		}
		
	},
	routes: [
		{
			path: '/auth/register',
			component: lazy(() => import('./RegisterPage'))
		}
	]
};

export default RegisterPageConfig;
