import DemoContent from '@fuse/core/DemoContent';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import { StyledHome } from "./StyledHome";
import logoAccept from "../../../images/aceptar.svg";
import logoChain from "../../../images/cadena.svg";
import logoCertificate from "../../../images/certificado.svg";
import logoBlockchain from "../../../images/cadena-de-bloques.png"
import logoSocya from "../../../images/logoSocya.png"
import logoTrazability from "../../../images/logoTrazability.png";
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  CardHeader
} from '@material-ui/core';
import Carousel from './Carousel/index';


const useStyles = makeStyles({
  layoutRoot: {}
});


function SimpleFullWidthSample() {
  const classes = useStyles();

  return (
    <>
      <StyledHome>
        <div className="img">
          <div className="logo">
            <img src="assets/images/logos/logo.png" alt="logo" />
            <br />
            <h1>Solución tecnológica para el registro, certificación <br />
              y trazabilidad del material gestionado en los proceso <br />
              de Economía Circular</h1>
          </div>
        </div>

        <div className="steps">
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Grid item style={{ textAlign: 'center', alignItems: "center" }}>
                <img src={logoChain} className="icon" alt="Celsia" style={{ margin: 'auto', width: '140px' }} />
                <br />
                <h4 style={{ textAlign: 'justify' }}>Gestores, Transformadores Productores y en general
                  todos los actores de la cadena, pueden vincularse y registar
                  la información de los procesos multiples para materiales.</h4>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid item style={{ textAlign: 'center', alignItems: "center" }}>
                <img src={logoCertificate} className="icon" alt="Celsia" style={{ margin: 'auto', width: '140px' }} />
                <br />
                <h4 style={{ textAlign: 'justify' }}>El sistema genera de forma automática los certificados de transformación
                  del material, únicos, transferibles e irremplazables que incluyen ademá
                  toda la trazabilidad del proceso.</h4>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid item style={{ textAlign: 'center', alignItems: "center" }}>
                <img src={logoAccept} className="icon" alt="Celsia" style={{ margin: 'auto', width: '140px' }} />
                <br />
                <h4 style={{ textAlign: 'justify' }}>La información registrada es validada y autorizada por una entidad
                  verificadora garantizando la legitimidad del actor y la transformación
                  reportada.</h4>
              </Grid>
            </Grid>
            <Grid style={{ backgroundColor: '#54AA3B', color: '#fff', borderRadius: '12px', padding: '10px', display: 'flex', alignItems: 'center' }} item md={12} xs={12}>
              <img src={logoBlockchain} className="icon" alt="Celsia" style={{ marginRight: '10px', width: '140px' }} />
              <h4>
                La información se registra y se procesa mediante tecnología Blockchain,
                lo que nos permite asegurar la calidad y confiabilidad de los datos reportados.
              </h4>
            </Grid>
          </Grid>
        </div>
        <div className="section3">
          <div style={{ textAlign: 'center' }}>
            <br />
            <h1 style={{ color: '#fff' }}>
              Con EcoRep las empresas pueden alcanzar <br />
              fácilmente sus metas en neutralidad de materiales, ya sea por <br />
              motivos regulatorios o voluntarios, obteniendo <br />
              certificados seguros y confiables
            </h1>
          </div>
        </div>
        <div className="section4">
          <Grid container style={{ padding: '60px', display: 'flex', alignItems: 'center' }} spacing={2}>
            <Grid item md={4} xs={12}>
              <div style={{ textAlign: 'left' }}>
                <br />
                <h2>
                  Ecorep es la mejor <br />
                  herramienta tecnológica para <br />
                  la gestión de sus procesos en <br />
                  Economia Circular.<br />
                </h2>
                <br />
                <h1 style={{ color: '#007355' }}>Descubra por qué:</h1>
              </div>
            </Grid>
            <Grid item md={8} xs={12}>
              <Carousel />
            </Grid>
          </Grid>
        </div>
        <div className="section5">
          <div style={{ textAlign: 'center' }}>
            <br />
            <h1 style={{ color: '#fff' }}>
              EcoRep es su gran aliado en la <br />
              neutralidad de materiales.
            </h1>
          </div>
        </div>


        <div className="section6">
          <Grid item style={{ textAlign: 'center', alignItems: "center" }}>
            <h1 style={{ textAlign: 'center', alignItems: "center", color: '#007355', fontSize: '20px' }}>Con el apoyo de:</h1>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <Grid item style={{ textAlign: 'center', alignItems: "center" }}>
                {/* <img src={logoChain} className="icon" alt="Celsia" style={{ margin: 'auto', width: '140px' }} /> */}
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid item style={{ textAlign: 'center', alignItems: "center" }}>
                <img src={logoSocya} className="icon" alt="Celsia" style={{ margin: 'auto', width: '440px' }} />
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid item style={{ textAlign: 'center', alignItems: "center" }}>
                {/* <img src={logoAccept} className="icon" alt="Celsia" style={{ margin: 'auto', width: '140px' }} /> */}
              </Grid>
            </Grid>
          </Grid>
        </div>

      </StyledHome>
    </>
  );
}

export default SimpleFullWidthSample;