import { lazy } from 'react';

const TransformedAdmMaterialConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/adm/material-transformed',
			component: lazy(() => import('./TransformedMaterialContainer'))
		}
	]
};

export default TransformedAdmMaterialConfig;