import { lazy } from 'react';

const ReceivedProducerTransformerTransferConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/producer/consult/received-transfer-info',
			component: lazy(() => import('./ReceivedTransferContainer'))
		}
	]
};

export default ReceivedProducerTransformerTransferConfig;


