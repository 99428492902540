import { lazy } from 'react';

const admCompaniesPageConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: true,
					style: 'static'
				},
			}
		}
	},
	routes: [
		{
			path: '/adm/users/companies',
			component: lazy(() => import('./AdmCompaniesPage'))
		}
	]
};

export default admCompaniesPageConfig;
