const superAdminNavigation = [
    {
        id: 'admConsults',
        title: 'Consultas',
        type: 'group',
        icon: 'verified_user',
        children: [
            {
                id: 'materialDelivery',
                title: 'Entrega de material',
                type: 'item',
                url: '/adm/material-delivery',
                icon: 'lock'
            },
            {
                id: 'materialFactured',
                title: 'Facturacion de material',
                type: 'item',
                url: '/adm/material/invoiced-info',
                icon: 'lock'
            },
            {
                id: 'materialTrasnformed',
                title: 'transformación de material',
                type: 'item',
                url: '/adm/material-transformed',
                icon: 'lock'
            },
            {
                id: 'certificatesInVerification',
                title: 'Certificados en verificación',
                type: 'item',
                url: '/adm/certificates-verification',
                icon: 'lock'
            },
            {
                id: 'certificatesAvaibles',
                title: 'Certificados aprobados',
                type: 'item',
                url: '/adm/certificates-avaibles',
                icon: 'lock'
            },
            {
                id: 'certificatesRejected',
                title: 'Certificados rechazados',
                type: 'item',
                url: '/adm/certificates-rejected',
                icon: 'lock'
            },
            {
                id: 'certificatesHistory',
                title: 'Certificados recibidos/transferidos',
                type: 'item',
                url: '/adm/certificates-history',
                icon: 'lock'
            },
        ]
    }, 
    {
        id: 'admUsers',
        title: 'Usuarios',
        type: 'group',
        icon: 'verified_user',
        children: [
            {
                id: 'companies',
                title: 'Compañias',
                type: 'item',
                url: '/adm/users/companies',
                icon: 'lock'
            },
            {
                id: 'createCompanyVerifier',
                title: 'Crear Empresa Verificadora',
                type: 'item',
                url: '/adm/users/company-verifier',
                icon: 'lock'
            },
        ]
    },
    {
        id: 'admCharges',
        title: 'Cobros',
        type: 'group',
        icon: 'verified_user',
        url:'/charges',
        children: [
        ]
    },
    {
        id: 'admParameters',
        title: 'Parametros',
        type: 'group',
        icon: 'verified_user',
        children: [
            {
                id: 'materials',
                title: 'Materiales',
                type: 'item',
                url: '/adm/parameters/materials',
                icon: 'lock'
            },
            
        ]
    }
];



export default superAdminNavigation;
