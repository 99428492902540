import { lazy } from 'react';

const TransformedAdmHistoryConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				},
			}
		}
		
	},
	routes: [
		{
			path: '/adm/certificates-history',
			component: lazy(() => import('./TransformedHistoryContainer'))
		}
	]
};

export default TransformedAdmHistoryConfig;


